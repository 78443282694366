define("discourse/plugins/discourse-adplugin/discourse/controllers/admin-plugins-house-ads-show", ["exports", "@glimmer/tracking", "@ember/controller", "@ember/object", "@ember/service", "@ember-compat/tracked-built-ins", "@ember-decorators/object", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/models/category", "I18n", "discourse/plugins/discourse-adplugin/discourse/components/modal/preview"], function (_exports, _tracking, _controller, _object, _service, _trackedBuiltIns, _object2, _ajax, _ajaxError, _category, _I18n, _preview) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let adminPluginsHouseAdsShow = _exports.default = (_dec = (0, _controller.inject)("adminPlugins.houseAds"), _dec2 = (0, _object2.observes)("model"), (_class = class adminPluginsHouseAdsShow extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _initializerDefineProperty(this, "modal", _descriptor2, this);
      _initializerDefineProperty(this, "houseAdsController", _descriptor3, this);
      _initializerDefineProperty(this, "selectedCategories", _descriptor4, this);
      _initializerDefineProperty(this, "selectedGroups", _descriptor5, this);
      _initializerDefineProperty(this, "saving", _descriptor6, this);
      _initializerDefineProperty(this, "savingStatus", _descriptor7, this);
      _initializerDefineProperty(this, "buffered", _descriptor8, this);
    }
    modelChanged() {
      this.buffered = new _trackedBuiltIns.TrackedObject({
        ...this.model
      });
      this.selectedCategories = this.model.categories || [];
      this.selectedGroups = this.model.group_ids || [];
    }
    get disabledSave() {
      for (const key in this.buffered) {
        // we don't want to compare the categories array
        if (key !== "categories" && this.buffered[key] !== this.model[key]) {
          return false;
        }
      }
      return true;
    }
    async save() {
      if (!this.saving) {
        this.saving = true;
        this.savingStatus = _I18n.default.t("saving");
        const data = {};
        const newRecord = !this.buffered.id;
        if (!newRecord) {
          data.id = this.buffered.id;
        }
        data.name = this.buffered.name;
        data.html = this.buffered.html;
        data.visible_to_logged_in_users = this.buffered.visible_to_logged_in_users;
        data.visible_to_anons = this.buffered.visible_to_anons;
        data.category_ids = this.buffered.category_ids;
        data.group_ids = this.buffered.group_ids;
        try {
          const ajaxData = await (0, _ajax.ajax)(newRecord ? `/admin/plugins/pluginad/house_creatives` : `/admin/plugins/pluginad/house_creatives/${this.buffered.id}`, {
            type: newRecord ? "POST" : "PUT",
            data
          });
          this.savingStatus = _I18n.default.t("saved");
          const houseAds = this.houseAdsController.model;
          if (newRecord) {
            this.buffered.id = ajaxData.house_ad.id;
            if (!houseAds.includes(this.buffered)) {
              houseAds.pushObject(_object.default.create(this.buffered));
            }
            this.router.transitionTo("adminPlugins.houseAds.show", this.buffered.id);
          } else {
            houseAds.find(ad => ad.id === this.buffered.id).setProperties(this.buffered);
          }
        } catch (error) {
          (0, _ajaxError.popupAjaxError)(error);
        } finally {
          this.set("model", this.buffered);
          this.saving = false;
          this.savingStatus = "";
        }
      }
    }
    setCategoryIds(categoryArray) {
      this.selectedCategories = categoryArray;
      this.buffered.category_ids = categoryArray.map(c => c.id);
      this.setCategoriesForBuffered();
    }
    setGroupIds(groupIds) {
      this.selectedGroups = groupIds;
      this.buffered.group_ids = groupIds.map(id => id);
    }
    cancel() {
      this.buffered = new _trackedBuiltIns.TrackedObject({
        ...this.model
      });
      this.selectedCategories = this.model.categories || [];
      this.selectedGroups = this.model.group_ids || [];
      this.setCategoriesForBuffered();
    }
    async destroy() {
      if (!this.buffered.id) {
        this.router.transitionTo("adminPlugins.houseAds.index");
        return;
      }
      try {
        await (0, _ajax.ajax)(`/admin/plugins/pluginad/house_creatives/${this.buffered.id}`, {
          type: "DELETE"
        });
        this.houseAdsController.model.removeObject(this.houseAdsController.model.findBy("id", this.buffered.id));
        this.router.transitionTo("adminPlugins.houseAds.index");
      } catch (error) {
        (0, _ajaxError.popupAjaxError)(error);
      }
    }
    openPreview() {
      this.modal.show(_preview.default, {
        model: {
          html: this.buffered.html
        }
      });
    }
    setCategoriesForBuffered() {
      // we need to fetch the categories because the serializer is not being used
      // to attach the category object to the house ads
      this.buffered.categories = this.buffered.category_ids ? this.buffered.category_ids.map(categoryId => _category.default.findById(categoryId)) : [];
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modal", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "houseAdsController", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "selectedCategories", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "selectedGroups", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "saving", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "savingStatus", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "buffered", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "modelChanged", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "modelChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setCategoryIds", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setCategoryIds"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setGroupIds", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setGroupIds"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "destroy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "destroy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openPreview", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openPreview"), _class.prototype)), _class));
});